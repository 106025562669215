<template>
  <div class="mirror-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <!-- 星星 -->
      <Star :totalStarNumber="mirrorImgList.length" :currentIndex="startNum" />
       <div class="title-area" v-if="titleInfo">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">
              {{ titleInfo.pinyin }}
            </div>
            <div class="title-hanzi font-hanzi-medium">
              {{ titleInfo.hanzi }}
            </div>
          </div>
        </div>
      <!-- 主体内容 -->
      <!-- 背景 -->
      <div class="bg-area">
        <img
          class="bg-img"
          :src="bgImg"
          alt=""
          v-if="!isAllSecreen"
        />
        <img
          :src="item.imgSrc"
          v-for="(item, index) in mirrorImgList"
          :key="index + 'img'"
          v-show="currentImgIndex === index"
          class="mirror-img"
          :class="{ allScreen: isAllSecreen }"
        />
      </div>
      <!-- 选项 -->
      <div class="option-area">
        <div
          class="option-item"
          :class="'lesson-' + lessonNo"
          v-for="(item, index) in optionList"
          :key="index"
        >
          <div
            class="word-content"
            @click.stop="handleCheckAnswer(item.id)"
            :class="{ leftBox: index === 0, rightBox: index === 1 }"
          >
            <div class="pinyin font-pinyin-medium">{{ item.pinyin }}</div>
            <div class="font-hanzi-medium">{{ item.hanzi }}</div>
          </div>
        </div>
      </div>

      <!-- 失败和成功背景 -->
      <div
        class="checkGif"
        :class="{ checkGifWrong: !checkGif }"
        v-checkgif="checkGif"
        v-if="isShowCheckGif"
      ></div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "../Star";
export default {
  name: "MirrorGame",
  components: {
    PageButton,
    Star,
  },
  props: {
    mirrorImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    optionList: {
      type: Array,
      require: true,
      default: () => [],
    },
    isAllSecreen: {
      type: Boolean,
      require: false,
      default: false,
    },
    lessonNo: {
      type: Number,
      require: false,
    },
    bgImg:{
      type: String,
      require: false,
    },
     titleInfo: {
      type: Object,
      require: false,
    },
  },

  data() {
    // 0 我  1 你
    return {
      checkGif: true,
      isShowCheckGif: false,
      currentImgIndex: 0,
      currentId: 1,
      isLastStep: false,
      startNum: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      checkSocketInfo: {},
    };
  },
  watch: {
    checkSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20058,
          data: { value },
          text: "MirrorGame页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("clickToCheckAnswer", ({ id }) => {
      this.handleCheckAnswer(id, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickToCheckAnswer");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleCheckAnswer(id, isFromSocket = false) {
      if (this.isLastStep) {
        return;
      }
      if (!isFromSocket) {
        this.checkSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      if (id === this.mirrorImgList[this.currentImgIndex].id) {
        this.isShowCheckGif = true;
        this.checkGif = true;
        this.currentImgIndex++;
        this.startNum++;
        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 1000);
        if (this.currentImgIndex === this.mirrorImgList.length) {
          startConfetti();
          playCorrectSound();
          this.isLastStep = true;
          this.currentImgIndex = this.mirrorImgList.length - 1;
        } else {
          playCorrectSound(true, false);
        }
      } else {
        playCorrectSound(false);
        this.isShowCheckGif = true;
        this.checkGif = false;
        setTimeout(() => {
          this.isShowCheckGif = false;
        }, 1000);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.mirror-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 48px;
    position: relative;
     .title-area {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    top: 10%;
    width: 30%;
    height: 17%;
    background: #224E96;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
    .bg-area {
      width: 100%;
      height: 100%;
      position: relative;
      .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 48px;
      }
      .mirror-img {
        width: 25%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        margin-bottom: 31%;
      }
      .allScreen {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: 0%;
        border-radius: 48px;
      }
    }
    .option-area {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .option-item {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .word-content {
          border-radius: 30px;
          width: 10vw;
          height: 10vw;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          cursor: pointer;
          div {
            text-align: center;
          }
        }
        .leftBox {
          background: #cd4c3f;
          border-bottom: 8px solid #9b352a;
          border-top: 8px solid #f07d70;
        }
        .leftBox:hover {
          background: #912016;
        }
        .rightBox {
          background: #224e96;
          border-bottom: 8px solid #113061;
          border-top: 8px solid #3a69b7;
        }
        .rightBox:hover {
          background: #173d79;
        }
      }
      .lesson-44 {
        margin-bottom: 25%;
        height: 40%;
        .word-content {
          width: 8vw;
          height: 8vw;
        }
      }
    }
  }
}
</style>
